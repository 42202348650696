/* eslint-disable no-undef */
import I18n from 'i18n-js'

I18n.defaultLocale = I18N_DEFAULT_LOCALE;
I18n.locale = I18N_LOCALE;
I18n.fallbacks = true;

window.I18n = I18n;

export default I18n
